import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import "../styles/index.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import Menu from "../pages/menu";
import Header from "../pages/header";
import RightColumn from "../pages/rightcolumn";

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch("/api/date");
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  return (
    <main>
      <Helmet>
        <title>Introduction</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col lg={1} md={1} xs={1}>
            <Menu />
          </Col>
          <Col lg={1} md={1} xs={1}></Col>
          <Col lg={8} md={8} xs={8}>
            <Header />

            <div id="introduction" className="font-futura max-width-600px">
              <h3 className="color-main font-biosans introStyle">Giới thiệu</h3>
              <p>
                English Phonetics Transcription là một công cụ do TUDIEN.NET
                phát triển giúp bạn thông thạo ngữ âm và âm vị học tiếng Anh
                nhanh chóng.
              </p>
              <p>
                Hãy bắt đầu với một ví dụ đơn giản. Từ "áp lực" trong tiếng Anh
                là pressure có phiên âm{" "}
                <span className="style-phonetics"> /'preʃə/</span>, trong đó âm
                vị phụ âm /ʃ/ có dạng chữ SS thay vì SH, vốn rất quen thuộc như
                trong she <span className="style-phonetics"> /ʃiː/</span>. Nếu
                chưa quen với hệ thống âm vị học quốc tế IPA - International
                Phonetics Alphabet, phiên âm của từ pressure có thể khiến bạn
                bối rối.
              </p>
              <p>
                Một điểm ngữ âm học quan trọng của Anh ngữ là có một sự khác
                biệt khá lớn giữa tương quan ÂM (sound) và CHỮ (word). Ví dụ /ʃ/
                là một âm cong lưỡi. Thông thường, các từ có tổ hợp phụ âm SH
                như she, show, shine etc... đều có cách đọc như nhau và phiên âm
                là /ʃ/. Tuy nhiên, khá nhiều từ vựng của tiếng Anh như assure
                <span className="style-phonetics"> /ə'ʃuə/</span>, sure
                <span className="style-phonetics"> /ʃuə/</span>, hoặc thậm chí
                "lạ" hơn như ocean
                <span className="style-phonetics"> /'ouʃn/</span>, efficient
                <span className="style-phonetics"> /I'fIʃnt/</span>, social
                <span className="style-phonetics"> /'səʊʃl/</span> hay sentient
                <span className="style-phonetics"> /'senʃnt/</span> cũng có cách
                phát âm tương tự và do đó, phiên âm là /ʃ/ thay vì /s/ như trên
                "mặt" chữ.
              </p>
              <p>
                Rõ ràng, với những người học tiếng Anh như ngôn ngữ thứ hai,
                việc học ngữ âm là rất quan trọng. Khi nào cần phát âm là /ʃ/,
                khi nào là /s/, vốn dĩ bản thân từ ngữ không phải lúc nào cũng
                giúp chúng ta nhận diện được. Chúng ta vẫn thường tra từ điển để
                nắm được âm chính xác. Nay với công cụ IPA, chúng ta có thể thực
                hiện thao tác truy cứu nhanh hơn.
              </p>
              <p>
                Chẳng hạn, với câu: She sells sea shells on the seashore - một
                câu khá quen thuộc trong việc luyện tập "thể dục" - tongue
                twisters với lưỡi có phiên âm là{" "}
                <span className="style-phonetics">
                  /ʃiː selz siː ʃelz ɒn ðə 'siːʃɔːr/
                </span>
                . Nếu quen với IPA, chúng ta có thể khá dễ dàng phát hiện ra âm
                /ʃ/ khác với s trong câu trên.
              </p>
              <p>
                Khó khăn duy nhất là chúng ta phải có đủ lượng thời gian luyện
                tập để hệ thống não bộ và bộ máy cấu âm - articulators có được
                phản xạ không điều kiện. Với IPA transcriptor, chúng ta sẽ từng
                bước luyện tập thuận lợi hơn.
              </p>
              <p>
                Tại
                <span className="color-main fontrem20">
                  {" "}
                  https://ipa.tudien.net
                </span>
                , bạn có thể dễ dàng tra cứu phiên âm âm vị học của nhiều từ,
                thậm chí một đoạn văn, thay vì từng từ một. Let's make phonetics
                and phonology great again!
              </p>
              <p>
                IPA is a project maintained by Vuong Nguyen. Built with Gatsby.
              </p>
            </div>
          </Col>
          <Col lg={2} md={2} xs={2}>
            <RightColumn index = "1"/>
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default Index;
